<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="12" lg="12" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">E-Fatura Ayarları</h4>
                </b-col>
              </div>
            </b-row>
            <b-row class="mt-5">
              <b-col lg="6" md="12" sm="12" cols="12">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                        label="Fatura Ünvanı"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-receipt"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceUnvan"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Telefon Numarası"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-phone"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceTelefon"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Vergi Dairesi"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-building"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceVD"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Vergi Numarası"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-hashtag"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceVN"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Adres"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-map-location"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceAdres"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="İl"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-city"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceIl"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="İlce"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-industry"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceIlce"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Kapı No"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-n"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"

                            v-model="item.invoiceKapiNo"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="6" md="12" sm="12" cols="12">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                        label="Hesap Unvan"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-align-left"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.hesapUnvan"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Banka Adı"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-building-columns"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.bankaAdi"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="İban no"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-hashtag"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.ibanNo"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="E-Fatura Kullanıcı Adı"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-user"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.invoiceUser"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="E-Fatura Kullanıcı Şifresi"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-lock"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.invoicePass"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="E Fatura Servis Sağlayıcı"
                        label-cols-md="4"
                    >
                      <v-select
                          :components="{ OpenIndicator, Deselect }"
                          v-model="item.invoiceServer"
                          label="title"
                          :options="eFaturaServisSaglayiciOptions"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Mersis No"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-circle-dot"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.invoiceMersis"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Ticaret Sicil No"
                        label-cols-md="4"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <font-awesome-icon icon="fa-solid fa-shekel-sign"/>
                        </b-input-group-prepend>
                        <b-form-input
                            autocomplete="off"
                            v-model="item.invoiceSicil"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col offset-md="4" class="d-flex justify-content-end">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="mr-1"
                        @click="update"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                    >
                      Ayarları Uygula
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BAvatar,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    vSelect,
    BAvatar,
    BContainer,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      Deselect: {
        render: (createElement) => createElement('span', ''),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', ''),
      },
      eFaturaGonderimiOptions: [
        {
          title: 'Kapalı',
          key: 0,
        },
        {
          title: 'Açık',
          key: 1,
        },
      ],
      degismeyenData: [],
      eFaturaServisSaglayiciOptions: [
        {
          title: 'UyumSoft',
          key: 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi',
        },
        {
          title: 'UyumSoft Kurumsal',
          key: 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi',
        },
        {
          title: 'UyumSoft Test API',
          key: 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi',
        },
      ],
      item: {
        invoiceUnvan: null,
        invoiceVD: null,
        invoiceVN: null,
        invoiceTelefon: null,
        invoiceAdres: null,
        invoiceIl: null,
        invoiceIlce: null,
        invoiceKapiNo: null,
        invoiceUser: null,
        invoicePass: null,
        invoiceServer: null,
        invoiceMersis: null,
        invoiceSicil: null,
        hesapUnvan: null,
        bankaAdi: null,
        ibanNo: null,
      },
    }
  },
  computed: {
    eFatura() {
      return store.getters.EFATURA_GET
    },
  },
  methods: {
    update() {
      var guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (key != 'invoiceServer') {
              if (this.item[key] !== undefined && this.item[key] !== null && this.item[key] != value) {
                guncellenenKeyler[key] = this.item[key]
              }
            }
          })
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'dashBoard')
      fd.append('methodName', 'buroAyar')
      fd.append('ayarGuncelle', 'true')
      fd.append('updateList[invoiceServer]', this.item.invoiceServer.key)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value)
          })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.degismeyenData = { ...res.data.ayarlar }
              this.ayarGet()
              store.commit('setToken', res.data.userToken)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.$refs['cari-info'].showLoading = false
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    ayarGet() {
      store.dispatch('eFatura')
          .then(res => {
            const ayarlar = { ...res.ayarlar }
            this.degismeyenData = res.ayarlar
            const t = this.item
            t.invoiceUnvan = ayarlar.invoiceUnvan
            t.invoiceVD = ayarlar.invoiceVD
            t.invoiceVN = ayarlar.invoiceVN
            t.invoiceTelefon = ayarlar.invoiceTelefon
            t.invoiceAdres = ayarlar.invoiceAdres
            t.invoiceIl = ayarlar.invoiceIl
            t.invoiceIlce = ayarlar.invoiceIlce
            t.invoiceKapiNo = ayarlar.invoiceKapiNo
            t.invoiceUser = ayarlar.invoiceUser
            t.invoicePass = ayarlar.invoicePass
            t.invoiceServer = ayarlar.invoiceServer == 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi' ? {
              title: 'UyumSoft',
              key: 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi',
            } : ayarlar.invoiceServer == 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi' ? {
              title: 'UyumSoft Kurumsal',
              key: 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi',
            } : {
              title: 'UyumSoft Test API',
              key: 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi',
            }
            t.invoiceMersis = ayarlar.invoiceMersis
            t.invoiceSicil = ayarlar.invoiceSicil
            t.hesapUnvan = ayarlar.hesapUnvan
            t.bankaAdi = ayarlar.bankaAdi
            t.ibanNo = ayarlar.ibanNo
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'list'].showLoading = false
      }, 1000)
    },
  },
  created() {
    this.ayarGet()
  },
  beforeDestroy() {
    store.commit('EFATURA_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
